/* For Tablets */
@media screen and (max-width: 850px) {
  .view {
    width: 400px;
  }
  .NavBarItems {
    z-index: 99;
    background-color: #8064a2;
    position: fixed;
    width: 100%;
  }

  .logo {
    justify-self: start;
    cursor: pointer;
  }

  .NavMenu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    border-radius: 13px;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: -110%;
    align-items: stretch;
    padding: 80px 0 30px 0;
    margin: 0;
    z-index: -1;
    transition: all 0.3s ease-in-out;
  }

  .NavMenu.active {
    left: 0%;
  }

  .NavLinks {
    display: block;
    widows: 100%;
    padding: 1.5rem 0;
    color: #fff;
    text-decoration: none;
    text-align: center;
    text-shadow: 0 0 1px #ff0000, 0 0 5px #8064a2;
  }

  .NavLinks:hover {
    opacity: 0.9;
    background-color: #8064a2;
    backdrop-filter: blur(20px);
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    color: #fff;
  }
  .NavLinks.active {
    opacity: 0.9;
    background-color: #8064a2;
    backdrop-filter: blur(20px);
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    color: #fff;
  }

  .MenuIcon {
    color: #fff;
    text-shadow: #ff0000;
    display: block;
    float: right;
    margin: 25px;
  }

  .hidden {
    display: none;
  }
}

@media screen and (min-width: 850px) {
  .NavBarItems {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    padding: 0 30px;
    background-color: #8064a2;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
    width: 100%;
    /* border-radius: 13px; */
    position: fixed;
    top: 0px;
    left: 50%;
    transform: translate(-50%);
  }

  .NavBarItems {
    z-index: 99;
    background-color: #8064a2;
    position: fixed;
    width: 100%;
  }

  .logo {
    justify-self: start;
    cursor: pointer;
  }

  .NavMenu {
    padding-top: 20px;
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 10px;
    align-items: center;
    text-align: center;
    justify-content: end;
  }

  .MenuActive {
    opacity: 0.9;
    background-color: #59486e;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    color: #fff;
  }

  .NavLinks {
    text-decoration: none;
    color: #fff;
    padding: 0.7rem 1rem;
    white-space: nowrap;
  }

  .NavLinks.active {
    opacity: 0.9;
    background-color: #59486e;
    border-radius: 4px;
    text-decoration: none;
    color: #fff;
    text-shadow: 0 0 1px #ff0000, 0 0 5px #8064a2;
  }

  .NavLinks:hover {
    opacity: 0.9;
    background-color: #59486e;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    color: #fff;
    text-shadow: 0 0 1px #ff0000, 0 0 5px #8064a2;
  }

  .MenuIcon {
    color: #fff;
    display: none;
  }
}
