@media screen and (max-width: 612px) {
  .label {
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
    /* float: none; */
    opacity: 0.6;
    /* background-clip: content-box; */
  }

  .text {
    text-align: justify;
    line-height: 32px;
  }

  .heading {
    font-weight: 400;
    font-size: x-large;
    /* margin-top: 10px;
    margin-bottom: 10px; */
    /* text-align: center; */
  }

  .left {
    margin-left: 5px;
    margin-right: 5px;
    width: 200px;
  }

  .right {
    margin-left: 5px;
    margin-right: 5px;
    width: 200px;
  }

  .paragraph {
    margin-bottom: 10%;
  }
}

/* For Tablets */
@media screen and (min-width: 612px) and (max-width: 950px) {
  .label {
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
    /* float: none; */
    opacity: 0.6;
    /* background-clip: content-box; */
  }

  .text {
    text-align: justify;
    line-height: 32px;
  }

  .heading {
    font-weight: 400;
    font-size: x-large;
    /* margin-top: 10px;
    margin-bottom: 10px; */
    /* text-align: center; */
  }

  .left {
    margin-left: 20px;
    margin-right: 20px;
    width: 300px;
  }

  .right {
    margin-left: 20px;
    margin-right: 20px;
    width: 300px;
  }

  .paragraph {
    margin-bottom: 10%;
  }
}

@media screen and (min-width: 950px) {
  .label {
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
    /* float: none; */
    opacity: 0.6;
    /* background-clip: content-box; */
  }

  .text {
    text-align: justify;
    line-height: 32px;
  }

  .heading {
    font-weight: 400;
    font-size: x-large;
    margin-top: 10px;
    margin-bottom: 10px;
    /* text-align: center; */
  }

  .image {
    width: 400px;
  }

  .left {
    margin-left: 50px;
    margin-right: 50px;
    width: 400px;
  }

  .right {
    margin-left: 50px;
    margin-right: 50px;
    width: 400px;
  }

  .paragraph {
    margin-bottom: 10%;
  }
}
