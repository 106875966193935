.label {
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 400;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  float: none;
  opacity: 0.6;
  background-clip: content-box;
}

.paragraph {
  opacity: 0.6;
}
