.homewrap {
  padding-top: 20px;
  margin-right: 15px;
  margin-left: 15px;
}

.mottowrap {
  width: 80%;
  margin-right: auto;
  margin-bottom: 85px;
  margin-left: auto;
  text-align: center;
}

.labellight {
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  float: none;
  opacity: 0.6;
  background-clip: content-box;
}

.heading {
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 36px;
  line-height: 50px;
  font-weight: 400;
  text-transform: none;
}
