@media screen and (max-width: 300px) {
  .button {
    background-color: #8064a2;
    color: #fff;
    padding: 0.5rem 1.5rem;
    border-radius: 4px;
    text-decoration: none;
    z-index: 9;
  }

  .container {
    position: relative;
    height: 100px;
  }

  .box {
    position: absolute;
    top: 30%;
    left: 100px;
  }

  .trendbox1 {
    position: absolute;
    top: 0px;
    left: 20px;
  }

  .trendbox2 {
    position: absolute;
    top: 06px;
    left: 23px;
  }

  .overlay {
    z-index: 9;
  }
}
@media screen and (min-width: 300px) and (max-width: 600px) {
  .button {
    background-color: #8064a2;
    color: #fff;
    padding: 0.5rem 1.5rem;
    border-radius: 4px;
    text-decoration: none;
    z-index: 9;
  }

  .container {
    position: relative;
    height: 100px;
  }

  .box {
    position: absolute;
    top: 30%;
    left: 150px;
  }

  .trendbox1 {
    position: absolute;
    top: 0px;
    left: 20px;
  }

  .trendbox2 {
    position: absolute;
    top: 06px;
    left: 23px;
  }

  .overlay {
    z-index: 9;
  }
}

@media screen and (min-width: 600px) and (max-width: 800px) {
  .homewrap {
    padding-top: 50px;
    margin-right: 15px;
    margin-left: 15px;
  }
  .card {
    width: 30%;
    position: absolute;
  }
  .image {
    size: 20%;
  }

  .content {
    align-items: center;
    margin-left: 10%;
  }

  .button {
    background-color: #8064a2;
    color: #fff;
    padding: 0.5rem 1.5rem;
    border-radius: 4px;
    text-decoration: none;
    z-index: 9;
  }

  .padding {
    margin-left: 40%;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .container {
    position: relative;
    height: 100px;
  }

  .box {
    position: absolute;
    top: 30%;
    left: 350px;
  }

  .trendbox1 {
    position: absolute;
    top: 0px;
    left: 200px;
  }

  .trendbox2 {
    position: absolute;
    top: 06px;
    left: 203px;
  }

  .overlay {
    z-index: 9;
  }
}

@media screen and (min-width: 800px) and (max-width: 1150px) {
  .homewrap {
    padding-top: 50px;
    margin-right: 15px;
    margin-left: 15px;
  }
  .card {
    width: 30%;
    position: absolute;
  }
  .image {
    size: 20%;
  }
  .content {
    align-items: center;
    margin-left: 10%;
  }

  .button {
    background-color: #8064a2;
    color: #fff;
    padding: 0.5rem 1.5rem;
    border-radius: 4px;
    text-decoration: none;
    z-index: 9;
  }

  .padding {
    margin-left: 40%;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .container {
    position: relative;
    height: 100px;
  }

  .box {
    position: absolute;
    top: 30%;
    left: 300px;
  }

  .trendbox1 {
    position: absolute;
    top: 0px;
    left: 150px;
  }

  .trendbox2 {
    position: absolute;
    top: 06px;
    left: 153px;
  }

  .overlay {
    z-index: 9;
  }
}

@media screen and (min-width: 1150px) and (max-width: 1500px) {
  .homewrap {
    padding-top: 50px;
    margin-right: 15px;
    margin-left: 15px;
  }
  .card {
    width: 30%;
    position: absolute;
  }
  .image {
    size: 20%;
  }

  .content {
    align-items: center;
    margin-left: 10%;
  }

  .button {
    background-color: #8064a2;
    color: #fff;
    padding: 0.5rem 1.5rem;
    border-radius: 4px;
    text-decoration: none;
    z-index: 9;
  }

  .padding {
    margin-left: 40%;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .container {
    position: relative;
    height: 100px;
  }

  .box {
    position: absolute;
    top: 30%;
    left: 500px;
  }

  .trendbox1 {
    position: absolute;
    top: 0px;
    left: 350px;
  }

  .trendbox2 {
    position: absolute;
    top: 06px;
    left: 353px;
  }

  .overlay {
    z-index: 9;
  }
}

@media screen and (min-width: 1500px) {
  .homewrap {
    padding-top: 50px;
    margin-right: 15px;
    margin-left: 15px;
  }
  .card {
    width: 30%;
    position: absolute;
  }
  .image {
    size: 20%;
  }

  .content {
    align-items: center;
    margin-left: 10%;
  }

  .button {
    background-color: #8064a2;
    color: #fff;
    padding: 0.5rem 1.5rem;
    border-radius: 4px;
    text-decoration: none;
    z-index: 9;
  }

  .padding {
    margin-left: 40%;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .container {
    position: relative;
    height: 100px;
  }

  .box {
    position: absolute;
    top: 30%;
    left: 500px;
  }

  .trendbox1 {
    position: absolute;
    top: 0px;
    left: 350px;
  }

  .trendbox2 {
    position: absolute;
    top: 06px;
    left: 353px;
  }

  .overlay {
    z-index: 9;
  }
}
