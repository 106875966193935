@media screen and (min-width: 781px) {
  .cardwidth {
    padding-top: 50px;
    padding-bottom: 50px;
    margin-right: 5px;
    margin-left: 5px;
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .cardwidth {
    padding-top: 50px;
    padding-bottom: 50px;
    margin-right: 5px;
    margin-left: 5px;
    width: 100%;
  }
}
@media screen and (max-width: 1000px) and (min-width: 900px) {
  .cardwidth {
    padding-top: 50px;
    padding-bottom: 50px;
    margin-right: 5px;
    margin-left: 5px;
    width: 100%;
  }
}

@media screen and (max-width: 1250px) and (min-width: 1000px) {
  .cardwidth {
    padding-top: 50px;
    padding-bottom: 50px;
    margin-right: 5px;
    margin-left: 5px;
    width: 90%;
  }
}

@media screen and (min-width: 1500px) {
  .cardwidth {
    padding-top: 50px;
    padding-bottom: 50px;
    margin-right: 15px;
    margin-left: 15px;
    width: 100%;
  }
  .rowheight {
    line-height: 16px;
  }
}
