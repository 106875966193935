.button {
  background-color: #8064a2;
  color: #fff;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  text-decoration: none;
  text-transform: uppercase;
}

.button:hover {
  text-shadow: 0 0 1px #ff0000, 0 0 5px #8064a2;
}

.padding {
  text-align: center;
}

.contact {
  text-align: left;
  float: right;
  color: white;
  text-shadow: 0 0 3px #ff0000, 0 0 5px #8064a2;
}
