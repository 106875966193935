.h1 {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 64px;
  /* line-height: 64px; */
  text-transform: none;
  text-shadow: 0 0 3px #ff0000, 0 0 5px #8064a2;
  color: white;
  overflow: hidden;
}

.h6 {
  text-align: center;
  font-size: 20px;
  line-height: 32px;
  text-transform: none;
  text-shadow: 0 0 3px #ff0000, 0 0 5px #8064a2;
  color: white;
}

.content {
  margin-left: 10%;
  margin-right: 10%;
  background-position: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
}

.image {
  background-position: 100%;
  padding-top: 200px;
  padding-bottom: 50px;
  background-size: cover;
}
